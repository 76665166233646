$qcolors: #FFF6DB 1, #e8ffe8 2, #efefff 3, #fdd 4, #fdf 5, #CCC 6

$bquote: ""

@each $t in $qcolors
  $i: nth($t, 2)
  $bquote: if($i == 1, "blockquote", selector_nest($bquote, "blockquote")) !global
  $bgc: nth($t, 1)
  $darkening: if($i == 2, 55%, 35%)
  $bc: darken($bgc, $darkening)
  $name2: if($i == 2, ", blockquote.secondary", "")
  $name3: if($i == 3, ", blockquote.tertiary", "")

  body #{$bquote + $name2 + $name3}, blockquote.bq#{$i}
    background-color: $bgc
    border-color: $bc
